<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        One postulated mechanism for the decomposition of ozone is via the following three-step
        mechanism:
      </p>

      <p class="pl-8">
        <chemical-latex content="NO(g) + O3(g) -> NO2(g) + O2(g)"></chemical-latex>
      </p>
      <p class="pl-8">
        <chemical-latex content="O3(g) -> O2(g) + O(g)"></chemical-latex>
      </p>
      <p class="pl-8">
        <chemical-latex content="NO2(g) + O(g) -> NO(g) + O2(g)"></chemical-latex>
      </p>

      <p class="mb-0">a) Which substance(s) are intermediates?</p>

      <p v-for="option in optionsPartsAB" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox
          v-model="inputs.intermediatesAnswers"
          :value="option.value"
          class="pl-8 mb-n4 mt-0"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-0 mt-3">b) Which substance(s) are catalysts?</p>

      <p v-for="option in optionsPartsAB" :key="'pt-2-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.catalystAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-5">c) What is the overall reaction?</p>

      <chemical-notation-input
        v-model="inputs.reactionAnswer"
        dense
        :show-note="false"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'Question484',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        intermediatesAnswers: [],
        catalystAnswers: [],
        reactionAnswer: null,
      },
      optionsPartsAB: [
        {text: '$\\ce{NO}$', value: 'NO'},
        {text: '$\\ce{O3}$', value: 'O3'},
        {text: '$\\ce{NO2}$', value: 'NO2'},
        {text: '$\\ce{O2}$', value: 'O2'},
        {text: '$\\ce{O}$', value: 'O'},
      ],
    };
  },
};
</script>
